import { Button, Checkbox, Icon } from '@momentum-ui/react'
import { navigate } from 'gatsby'
import isEqual from 'lodash.isequal'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ImageSelector from '../../ImageSelector'
import defaultGreen from '../../../assets/images/defaultGreen.png'
import defaultPurple from '../../../assets/images/defaultPurple.png'
import defaultOrange from '../../../assets/images/defaultOrange.png'
import {
  NAME_VALIDATOR,
  DESCRIPTION_VALIDATOR,
  EMAIL_VALIDATOR,
  DOMAIN_ONLY_VALIDATOR,
} from '../applicationValidators'
import { SERVICE_APP_ACTION } from '../../../constants/metrics'
import httpService from '../../../services/httpService'
import { logError } from '../../../services/loggerService'
import MetricsService from '../../../services/metricsService'
import { retrieveToken, retrieveFeatureFlags } from '../../../state/auth'
import { AppState } from '../../../state/store'
import Dialog from '../../Dialog'
import ErrorAlert from '../../ErrorAlert'
import Link from '../../Link'
import ActionInput from '../ActionInput'
import EditButtons from '../EditButtons'
import { getBffBaseUrl } from '../../../services/configService'
import { getCIUrls } from '../../../utils/auth.utils'
import {
  dateFormatter,
  hasValidFeatureFlag,
} from '../../../utils/general.utils'
import { APPLICATION_SUBMITTED } from '../../../constants/applications'
import {
  IDataSourceSchema,
  IServiceApp,
} from '../../../interfaces/myAppInterfaces'
import OrgAuthorizations from '../OrgAuthorizations'
import { byodsScopes, getAppScopes } from '../../../templates/myApps/config'
import PlugServiceDown from '../../../assets/svgs/plug-service-down-icon.svg'

interface IServiceAppProps {
  serviceApp?: IServiceApp
  hasCurrentApplications?: boolean
  updateName?: Function
}

const ServiceApp: React.FC<IServiceAppProps> = ({
  serviceApp,
  hasCurrentApplications,
  updateName,
}) => {
  const { t } = useTranslation()
  const metricsService = MetricsService.getInstance()

  const [clientSecret, setClientSecret] = useState<any>(serviceApp?.secret)
  const token = useSelector((state: AppState) => retrieveToken(state))
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isSubmittingAuth, setIsSubmittingAuth] = useState<boolean>(false)
  const [acceptedToS, setacceptedToS] = useState<boolean>(false)
  const existingServiceApp = !!serviceApp
  const [hasError, setHasError] = useState<boolean>(false)
  const [scopeTouched, setScopeTouched] = useState<boolean>(false)
  const [schemaTouched, setSchemaTouched] = useState<boolean>(false)
  const [validationErrors, setValidationErrors] = useState<object>({})
  const [dataSourceSchemaList, setDataSourceSchemaList] = useState<
    IDataSourceSchema[]
  >([])
  const [formInitial, setFormInitial] = useState<any>({
    name: serviceApp?.name || '',
    logo: serviceApp?.logo || '',
    contactEmail: serviceApp?.contactEmail || '',
    description: serviceApp?.description || '',
    scopes: serviceApp?.scopes || [],
    orgSubmissionStatus: serviceApp?.orgSubmissionStatus || 'none',
    submissionStatus: serviceApp?.submissionStatus || '',
    submissionDate: serviceApp?.submissionDate || '',
    isSubmissionFeatured: serviceApp?.isFeatured || false,
    validDomains: serviceApp?.validDomains?.length
      ? serviceApp.validDomains
      : [''],
    dataSourceSchemaIds: serviceApp?.dataSourceSchemaIds || [],
  })

  const [formDiff, setFormDiff] = useState<any>({
    name: formInitial.name,
    description: formInitial.description,
    scopes: formInitial.scopes,
    logo: formInitial.logo,
    contactEmail: formInitial.contactEmail,
    validDomains: formInitial.validDomains,
    dataSourceSchemaIds: formInitial.dataSourceSchemaIds,
  })
  const [dialogState, setDialogState] = useState<any>({
    isShowing: false,
    type: '',
  })

  const hasEmptyScopeError = scopeTouched && !formDiff.scopes.length
  const hasEmptySchemaError =
    schemaTouched && !formDiff.dataSourceSchemaIds.length
  const hasEmptyLogoError = !('File' in window && formDiff.logo instanceof File)

  const userFeatureFlags = useSelector((state: AppState) =>
    retrieveFeatureFlags(state)
  )

  const hasCloudContactFeatureFlag = hasValidFeatureFlag(
    userFeatureFlags,
    `${process.env.GATSBY_CLOUD_CONTACT_NEEDS_FEATURE_FLAG}`,
    `${process.env.GATSBY_CLOUD_CONTACT_FEATURE_FLAG}`
  )

  const hasServiceAppFeatureFlag = hasValidFeatureFlag(
    userFeatureFlags,
    `${process.env.GATSBY_SERVICE_APP_NEEDS_FEATURE_FLAG}`,
    `${process.env.GATSBY_SERVICE_APP_FEATURE_FLAG}`
  )

  const hasByodsFeatureFlag = hasValidFeatureFlag(
    userFeatureFlags,
    `${process.env.GATSBY_BYODS_NEEDS_FEATURE_FLAG}`,
    `${process.env.GATSBY_BYODS_FEATURE_FLAG}`
  )

  const scopes = getAppScopes(
    hasCloudContactFeatureFlag,
    hasServiceAppFeatureFlag,
    hasByodsFeatureFlag
  )

  const canShowSubmitToApphub =
    existingServiceApp &&
    serviceApp?.submissionStatus?.toLowerCase() !== APPLICATION_SUBMITTED

  const { idBrokerUrl } = getCIUrls()
  const httpConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      idBrokerHost: idBrokerUrl,
    },
  }

  useEffect(() => {
    let isMounted = true
    const fetchDataSourceSchemaList = async (): Promise<void> => {
      if (hasByodsFeatureFlag) {
        try {
          const { data } = await httpService.get(
            `${getBffBaseUrl()}/v1/applications/dataSourceSchemas`,
            httpConfig
          )
          if (Array.isArray(data) && data.length && isMounted) {
            setDataSourceSchemaList(data)
          }
        } catch (err) {
          if (isMounted) {
            setHasError(true)
            logError(
              `An error has occurred while fetching the data source schema list: ${err}`
            )
          }
        }
      }
    }
    fetchDataSourceSchemaList()

    return (): void => {
      isMounted = false
    }
  }, [])

  const isDirty = (serviceAppField: string): boolean => {
    return !isEqual(formInitial[serviceAppField], formDiff[serviceAppField])
  }

  const getFormData = (formFields: { [key: string]: any }): FormData => {
    const formData = new FormData()

    const { logo, ...fields } = formFields

    const rawFormData: { [key: string]: any } = {
      ...(logo && { logo }),
      app: JSON.stringify({
        ...fields,
        type: 'serviceApp',
      }),
    }

    for (const key in rawFormData) {
      formData.append(key, rawFormData[key])
    }

    return formData
  }

  const handleFormSubmit = async (serviceAppField: string): Promise<void> => {
    setIsSubmitting(true)

    try {
      let fieldsToUpdate: { [key: string]: any } = {}
      // Service App field submit
      if (serviceAppField && !!serviceApp) {
        if (serviceAppField === 'orgSubmissionStatus') {
          fieldsToUpdate[serviceAppField] = 'submitted'
        } else {
          fieldsToUpdate[serviceAppField] = formDiff[serviceAppField]
        }
        const formData = getFormData(fieldsToUpdate)
        await httpService.patch(
          `${getBffBaseUrl()}/v1/applications/serviceApp/${serviceApp.id}`,
          formData,
          httpConfig
        )
        setIsSubmitting(false)

        metricsService.track(SERVICE_APP_ACTION, {
          type: 'Update',
        })

        if (serviceAppField === 'name' && updateName) {
          updateName(formDiff.name)
        }
        setFormInitial({ ...formInitial, ...fieldsToUpdate })
      } else {
        // Service App creating
        fieldsToUpdate = { ...formDiff }
        if (!hasByodsScopes(formDiff)) {
          for (const field of ['validDomains', 'dataSourceSchemaIds']) {
            delete fieldsToUpdate[field]
          }
        }
        const formData = getFormData(fieldsToUpdate)
        const resp = await httpService.post(
          `${getBffBaseUrl()}/v1/applications/serviceApp`,
          formData,
          httpConfig
        )
        setIsSubmitting(false)

        metricsService.track(SERVICE_APP_ACTION, {
          type: 'Create',
          scopes: fieldsToUpdate.scopes,
        })

        navigate(`/my-apps/${resp.data.id}`, {
          state: {
            serviceAppName: fieldsToUpdate.name,
            clientId: resp.data.id,
            clientSecret: resp.data.secret,
          },
        })
      }
    } catch (err) {
      setIsSubmitting(false)
      setHasError(true)
      logError(`An error has occurred while adding the Service App ${err}`)
    }
  }

  const handleCreateCancel = (): void => {
    if (hasCurrentApplications) {
      navigate('/my-apps')
    } else {
      navigate('/my-apps/new')
    }
  }

  const handleEditCancel = (serviceAppField: string): void => {
    const cleanDiff = { ...formDiff }
    cleanDiff[serviceAppField] = formInitial[serviceAppField]
    setFormDiff({
      ...cleanDiff,
    })
    handleValidationState(false, serviceAppField)
  }

  const deleteServiceApp = async (): Promise<void> => {
    try {
      setIsSubmitting(true)
      await httpService.delete(
        `${getBffBaseUrl()}/v1/applications/${serviceApp!.id}`,
        httpConfig
      )

      metricsService.track(SERVICE_APP_ACTION, {
        type: 'Delete',
      })
      setIsSubmitting(false)
      navigate('/my-apps')
    } catch (err) {
      setIsSubmitting(false)
      setHasError(true)
      logError(`An error has occurred while deleting the service app ${err}`)
    }
  }

  const regenerateSecret = async (): Promise<void> => {
    try {
      const secret = await httpService.patch(
        `${getBffBaseUrl()}/v1/applications/${serviceApp?.id}/reset`,
        {},
        httpConfig
      )

      metricsService.track(SERVICE_APP_ACTION, {
        type: 'Reset Client Secret',
      })

      setClientSecret(secret.data.secret)
    } catch (err) {
      setHasError(true)
      logError(
        `An error has occurred while regenerating the client secret ${err}`
      )
    }
  }

  const renderDialog = (): JSX.Element => {
    const closeDialog = (): void => {
      setDialogState({ isShowing: false, type: '' })
    }
    const isDelete = dialogState?.type === 'delete'
    const isModified = isDelete
      ? t('serviceApp.deleteServiceAppDialogText')
      : t('serviceApp.resetClientSecretDialogText')
    return (
      <Dialog
        dialogText={isModified}
        onSuccess={(): void => {
          if (isDelete) {
            deleteServiceApp()
          } else {
            regenerateSecret()
          }
          closeDialog()
        }}
        onClose={closeDialog}
        showDialog={dialogState?.isShowing}
      />
    )
  }

  const renderAdminAuthSection = (): JSX.Element => {
    return (
      <>
        {formInitial.orgSubmissionStatus === 'none' ? (
          <>
            <Button
              ariaLabel={t('serviceApp.adminAuthorization')}
              className="md-button md-button--blue-outline"
              id="admin-authorization"
              onClick={async (): Promise<void> => {
                await handleAdminAuth()
              }}
              loading={isSubmittingAuth}
            >
              {t('serviceApp.requestAdminAuth')}
            </Button>
            <div className="apphub-tip">
              <p>{t('serviceApp.adminAuthTip')}</p>
            </div>
          </>
        ) : (
          <>
            <label>
              <span>{t('serviceApp.submissionStatus')}</span>
            </label>
            <p data-testid="submission-status">
              {t('serviceApp.submissionStatusSubmitted')}
            </p>
          </>
        )}
      </>
    )
  }

  const handleToSLabelInteraction = (e: any): void => {
    // enter, space or clicking on label should change the checkbox
    if (e.key === ' ' || e.key === 'Enter' || !e.key) {
      setacceptedToS(!acceptedToS)
    }
  }

  const handleValidationState = (isInvalid: boolean, field: string): void => {
    setValidationErrors({ ...validationErrors, ...{ [field]: isInvalid } })
  }

  const handleAdminAuth = async (): Promise<void> => {
    setIsSubmittingAuth(true)
    setFormDiff({
      orgSubmissionStatus: 'submitted',
      ...formDiff,
    })

    await handleFormSubmit('orgSubmissionStatus')
    setIsSubmittingAuth(false)
  }

  const removeUri = (idx: number): void => {
    const newUris = formDiff.validDomains.slice(0)
    newUris.splice(idx, 1)
    setFormDiff({ ...formDiff, validDomains: newUris })
  }

  const addUri = (): void => {
    setFormDiff({
      ...formDiff,
      validDomains: [...formDiff.validDomains, ''],
    })
  }

  const hasValidationErrors = Object.values(validationErrors).includes(true)

  const { dataSourceSchemaIds, ...cleanedFormDiff } = formDiff
  const hasFormChanges =
    (Object.values(cleanedFormDiff) as []).filter(
      (val: any) => val.length || val instanceof File
    ).length === Object.keys(cleanedFormDiff).length

  const disableFormSubmit =
    !acceptedToS ||
    hasValidationErrors ||
    hasEmptyScopeError ||
    hasEmptyLogoError ||
    !hasFormChanges

  const hasByodsScopes = (formObj: any) => {
    return (
      hasByodsFeatureFlag &&
      byodsScopes.some((scope) => formObj.scopes.indexOf(scope) > -1)
    )
  }

  const canShowByods =
    (hasByodsScopes(formDiff) && !existingServiceApp) ||
    (hasByodsScopes(formInitial) && existingServiceApp)

  return (
    <div className="application-form-container application-form-heading">
      {existingServiceApp &&
        serviceApp?.submissionStatus?.toLowerCase() ===
          APPLICATION_SUBMITTED && (
          <div className="form-row" data-cy="submission-status-section">
            <div className="columns medium-3">
              <label>
                <h3>{t('serviceApp.webexAppHub')}</h3>
              </label>
              <p>{t('serviceApp.webexAppHubLabel')}</p>
            </div>
            <div className="columns medium-6 medium-offset-1">
              <label>
                <span>{t('serviceApp.submissionStatus')}</span>
              </label>
              <p
                className="submissionStatusDate"
                data-testid="submission-status"
              >
                {t('apphubSubmission.submittedOn')}{' '}
                {dateFormatter(
                  (serviceApp?.submissionDate as unknown) as number
                )}
              </p>
              <div>
                <label>
                  <span>{t('serviceApp.featured')}</span>
                </label>
                <p>
                  {serviceApp?.isFeatured === true
                    ? t('serviceApp.isSubmissionFeatured.true')
                    : t('serviceApp.isSubmissionFeatured.false')}
                </p>
              </div>
            </div>
            <div className="columns medium-2" />
          </div>
        )}

      {existingServiceApp && (
        <>
          <div className="form-row">
            <div className="columns medium-3">
              <label htmlFor="admin-authorization">
                <h3>{t('serviceApp.adminAuthorization')}</h3>
              </label>
              <p>{t('serviceApp.adminAuthLabel')}</p>
            </div>
            <div className="columns medium-6 medium-offset-1">
              {renderAdminAuthSection()}
            </div>
            <div className="columns medium-2" />
          </div>

          {canShowSubmitToApphub ? (
            <div className="form-row">
              <div className="columns medium-3">
                <label htmlFor="submit-to-apphub">
                  <h3>{t('serviceApp.submitToApphub')}</h3>
                </label>
                <p>{t('serviceApp.submitToApphubLabel')}</p>
              </div>
              <div className="columns medium-6 medium-offset-1">
                <Link
                  to={`/my-apps/${serviceApp!.id}/submit`}
                  state={{ application: serviceApp }}
                  className={`md-button md-button--blue-outline submit-button`}
                  id="submit-to-apphub"
                >
                  {t('serviceApp.submitToApphub')}
                </Link>
                <div className="apphub-tip">
                  <p>
                    {t('serviceApp.submitToApphubTip1')}
                    <Link
                      to="/documentation/app-submission-process"
                      className={`link`}
                      id="apphub-guideline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('serviceApp.apphubGuideline')}
                    </Link>
                    {t('serviceApp.submitToApphubTip2')}
                  </p>
                </div>
              </div>
              <div className="columns medium-2" />
            </div>
          ) : null}

          <div className="form-row">
            <div className="columns medium-3">
              <h3>{t('serviceApp.oauthSettings')}</h3>
            </div>
            <div className="columns medium-6 medium-offset-1">
              <label htmlFor="client-id">
                <span>{t('serviceApp.clientId')}</span>
              </label>
              <ActionInput
                name="clientId"
                htmlId="client-id"
                value={serviceApp?.id}
                readOnly
                copyable
                data-cy="client-id"
              />
              <div className="client-secret-container">
                <label htmlFor="client-secret">
                  <span>{t('serviceApp.clientSecret')}</span>
                </label>
                {clientSecret ? (
                  <ActionInput
                    name="clientSecret"
                    htmlId="client-secret"
                    value={clientSecret}
                    readOnly
                    copyable
                  />
                ) : (
                  <button
                    className="inline-text-btn"
                    onClick={(): void =>
                      setDialogState({ isShowing: true, type: 'secret' })
                    }
                    data-cy="reset-client-secret-btn"
                  >
                    {t('serviceApp.regenerateClientSecret')}
                  </button>
                )}
              </div>
            </div>
            <div className="columns medium-2" />
          </div>
          <OrgAuthorizations
            canShow={
              !!serviceApp?.authorizationsCount &&
              serviceApp.authorizationsCount > 0
            }
            serviceApp={serviceApp}
            token={token}
            idBrokerUrl={idBrokerUrl}
          />
        </>
      )}

      <div className="form-row">
        <div className="columns medium-3">
          <label htmlFor="service-app-name">
            <h3>{t('serviceApp.name')}</h3>
            <span>*</span>
          </label>
          <p>{t('serviceApp.nameLabel')}</p>
        </div>
        <div className="columns medium-6 medium-offset-1">
          <ActionInput
            name="serviceAppName"
            htmlId="service-app-name"
            placeholder={t('serviceApp.namePlaceholder')}
            value={formDiff.name}
            showEditButton={existingServiceApp}
            onChange={(input: Event): void => {
              setFormDiff({
                ...formDiff,
                name: (input.target as HTMLInputElement).value,
              })
            }}
            handleSave={async (): Promise<void> => {
              await handleFormSubmit('name')
            }}
            handleCancel={(): void => {
              handleEditCancel('name')
            }}
            disableSave={!isDirty('name')}
            isLoading={isSubmitting}
            validation={NAME_VALIDATOR}
            handleValidationState={handleValidationState}
          />
        </div>
        <div className="columns medium-2" />
      </div>

      <div className="form-row">
        <div className="columns medium-3">
          <label htmlFor="apphubSubmission-icon">
            <h3>{t('apphubSubmission.icon')}</h3>
            <span>*</span>
          </label>
          <p>{t('apphubSubmission.iconLabel')}</p>
        </div>
        <div className="columns medium-6 medium-offset-1">
          <ImageSelector
            id="logo"
            name="logo"
            shape="circle"
            imageUrl={formInitial.logo}
            presets={[
              { url: defaultOrange, label: t('apphubSubmission.default1') },
              { url: defaultPurple, label: t('apphubSubmission.default2') },
              { url: defaultGreen, label: t('apphubSubmission.default3') },
            ]}
            handleValidationState={handleValidationState}
            onChange={(value: File): void => {
              setFormDiff({
                ...formDiff,
                logo: value,
              })
            }}
            onSave={async (): Promise<void> => {
              await handleFormSubmit('logo')
            }}
            isSaving={isSubmitting}
            hasError={hasError}
            imgRequiredErrorMsg="serviceApp.logoRequiredMessage"
            withSaveButtons={existingServiceApp}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="columns medium-3">
          <label htmlFor="service-app-description">
            <h3>{t('serviceApp.description')}</h3>
            <span>*</span>
          </label>
          <p>{t('serviceApp.descriptionLabel')}</p>
        </div>
        <div className="columns medium-6 medium-offset-1">
          <ActionInput
            htmlId="service-app-description"
            name="serviceAppDescription"
            value={formDiff.description}
            rows={10}
            textArea
            showEditButton={existingServiceApp}
            onChange={(input: Event): void => {
              setFormDiff({
                ...formDiff,
                description: (input.target as HTMLInputElement).value,
              })
            }}
            handleSave={async (): Promise<void> => {
              await handleFormSubmit('description')
            }}
            handleCancel={(): void => {
              handleEditCancel('description')
            }}
            disableSave={!isDirty('description')}
            isLoading={isSubmitting}
            validation={DESCRIPTION_VALIDATOR}
            handleValidationState={handleValidationState}
          />
        </div>
        <div className="columns medium-2" />
      </div>

      {!existingServiceApp && (
        <div className="form-row">
          <div className="columns medium-3">
            <label htmlFor="service-app-contact-email">
              <h3>{t('apphubSubmission.contactEmail')}</h3>
              <span>*</span>
            </label>
          </div>
          <div className="columns medium-6 medium-offset-1">
            <ActionInput
              name="contactEmail"
              htmlId="service-app-contact-email"
              placeholder={t('apphubSubmission.contactEmailPlaceholder')}
              value={formDiff.contactEmail}
              onChange={(input: Event): void => {
                setFormDiff({
                  ...formDiff,
                  contactEmail: (input.target as HTMLInputElement).value,
                })
              }}
              validation={EMAIL_VALIDATOR}
              handleValidationState={handleValidationState}
            />
          </div>
          <div className="columns medium-2" />
        </div>
      )}

      <div className="form-row">
        <div className="columns medium-3">
          <h3>{t('serviceApp.scopes')}</h3>
          <span>*</span>
          <p>{t('serviceApp.scopesLabel')}</p>
        </div>
        <div className="columns medium-6 medium-offset-1">
          {scopes.map((scope: string, id) => {
            return (
              <Checkbox
                value={scope}
                key={id}
                label={scope}
                htmlId={scope}
                checked={formDiff.scopes.includes(scope)}
                onClick={(): void => {
                  const scopesArr = [...formDiff.scopes]
                  const index = scopesArr.indexOf(scope)
                  if (index > -1) {
                    scopesArr.splice(index, 1)
                  } else {
                    scopesArr.push(scope)
                  }
                  setFormDiff({
                    ...formDiff,
                    scopes: scopesArr,
                  })
                  setScopeTouched(true)
                }}
                data-cy={`scope-${id}`}
                data-testid={`scope-${id}`}
              />
            )
          })}
          {hasEmptyScopeError && (
            <div className="md-input-container md-error">
              <div className="md-input__messages">
                <div className="md-input__message" role="alert">
                  {t('serviceApp.scopesValidationMessage')}
                </div>
              </div>
            </div>
          )}
          {existingServiceApp && (
            <EditButtons
              show={isDirty('scopes')}
              handleCancel={(): void => {
                handleEditCancel('scopes')
              }}
              handleSave={async (): Promise<void> => {
                await handleFormSubmit('scopes')
              }}
              isLoading={isSubmitting}
              existingApplication={existingServiceApp}
              disableSave={hasEmptyScopeError}
            />
          )}
        </div>
        <div className="columns medium-2" />
      </div>

      {/*------------------- Begin BYODS section -------------------------- */}
      {canShowByods && (
        <>
          <div className="form-row" data-testid="byods-section">
            <div className="columns medium-9">
              <label htmlFor="data-domain">
                <h3 className="super">{t('serviceApp.byodsTitle')}</h3>
              </label>
              <p>{t('serviceApp.byodsLabel')}</p>
              <p>
                {t('serviceApp.byodsReadMore')}
                <Link
                  to={'/documentation'}
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('serviceApp.byodsDocumentation')}
                </Link>
              </p>
            </div>
            <div className="columns medium-2" />
          </div>

          <div className="form-row borderless">
            <div className="columns medium-3">
              <label htmlFor="data-domain">
                <h3>{t('serviceApp.dataDomain')}</h3>
              </label>
              <p>{t('serviceApp.dataDomainLabel')}</p>
              <Link
                to={'/documentation'}
                className="link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>{t('serviceApp.learnMore')}</p>
              </Link>
            </div>
            <div className="columns medium-6 medium-offset-1">
              <ActionInput
                name="dataDomain"
                htmlId="data-domain"
                placeholder={t('serviceApp.dataDomainPlaceholder')}
                value={formDiff.validDomains}
                showEditButton={existingServiceApp}
                onChange={(input: Event, idx: any): void => {
                  const domainsCopy = formDiff.validDomains.slice(0)
                  domainsCopy[idx] = (input.target as HTMLInputElement).value
                  setFormDiff({
                    ...formDiff,
                    validDomains: domainsCopy,
                  })
                }}
                handleSave={async (): Promise<void> => {
                  await handleFormSubmit('validDomains')
                }}
                handleCancel={(): void => {
                  handleEditCancel('validDomains')
                }}
                disableSave={!isDirty('validDomains')}
                isLoading={isSubmitting}
                onRemoveInput={removeUri}
                onAddInput={addUri}
                addInputButtonText={t('serviceApp.addDomain')}
                validation={DOMAIN_ONLY_VALIDATOR}
              />
            </div>
            <div className="columns medium-2" />
          </div>

          <div className="form-row borderless">
            <div className="columns medium-3">
              <label htmlFor="data-domain">
                <h3>{t('serviceApp.dataSchema')}</h3>
              </label>
              <p>{t('serviceApp.dataSchemaLabel')}</p>
              <Link
                to={'/documentation'}
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                <p>
                  {t('serviceApp.schemaDocumentation')}{' '}
                  <Icon name="pop-out_12" />
                </p>
              </Link>
            </div>
            <div className="columns medium-6 medium-offset-1">
              {dataSourceSchemaList.length ? (
                dataSourceSchemaList.map((schema: any, id) => {
                  return (
                    <Checkbox
                      value={schema.id}
                      key={id}
                      label={
                        <>
                          {schema.serviceType}{' '}
                          <Link
                            to={schema.documentationUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="link"
                          >
                            {t('serviceApp.learnMore')}{' '}
                            <Icon name="pop-out_12" />
                          </Link>
                        </>
                      }
                      htmlId={`schema-${id}`}
                      checked={formDiff.dataSourceSchemaIds.includes(schema.id)}
                      onClick={(): void => {
                        const schemaArr = [...formDiff.dataSourceSchemaIds]
                        const index = schemaArr.indexOf(schema.id)
                        if (index > -1) {
                          schemaArr.splice(index, 1)
                        } else {
                          schemaArr.push(schema.id)
                        }
                        setFormDiff({
                          ...formDiff,
                          dataSourceSchemaIds: schemaArr,
                        })
                        setSchemaTouched(true)
                      }}
                      data-cy={`schema-${id}`}
                    />
                  )
                })
              ) : (
                <div className="service-down-error medium-10">
                  <div className="icon-wrapper">
                    <PlugServiceDown className="error-svg" />
                  </div>
                  <h3>{t('myApps.serviceDown')}</h3>
                  <p>{t('myApps.serviceDownDescription')}</p>
                </div>
              )}
              {hasEmptySchemaError && (
                <div className="md-input-container md-error">
                  <div className="md-input__messages">
                    <div className="md-input__message" role="alert">
                      {t('serviceApp.schemaValidationMessage')}
                    </div>
                  </div>
                </div>
              )}
              {existingServiceApp && (
                <EditButtons
                  show={isDirty('dataSourceSchemaIds')}
                  handleCancel={(): void => {
                    handleEditCancel('dataSourceSchemaIds')
                  }}
                  handleSave={async (): Promise<void> => {
                    await handleFormSubmit('dataSourceSchemaIds')
                  }}
                  isLoading={isSubmitting}
                  existingApplication={existingServiceApp}
                  disableSave={hasEmptySchemaError}
                />
              )}
            </div>
            <div className="columns medium-2" />
          </div>
        </>
      )}
      {/* ------------------ End BYODS section -------------------- */}

      {existingServiceApp ? (
        <div className="form-row btn-row-left">
          <div className="columns medium-3">
            <h3>{t('serviceApp.deleteServiceApp')}</h3>
            <p>{t('serviceApp.deleteServiceAppLabel')}</p>
          </div>
          <div className="columns medium-6 medium-offset-1">
            <Button
              id="service-app-delete-button"
              ariaLabel={t('serviceApp.deleteServiceApp')}
              onClick={(): void =>
                setDialogState({ isShowing: true, type: 'delete' })
              }
              role="button"
              color="red"
              data-cy="delete-service-app-btn"
              className={isSubmitting ? 'disabled' : ''}
            >
              {t('serviceApp.deleteServiceApp')}
            </Button>
          </div>
          <div className="columns medium-2" />
        </div>
      ) : null}

      <div className="form-row btn-row">
        <div className="columns">
          <Checkbox
            className="tos-checkbox"
            value="agreement-checkbox"
            checked={acceptedToS || existingServiceApp}
            htmlId={'acceptStatement'}
            onClick={(): void => {
              setacceptedToS(!acceptedToS)
            }}
            data-cy="tos-checkbox"
            name="tos-checkbox"
            disabled={existingServiceApp}
            data-testid="tos-checkbox"
          >
            <span
              onClick={handleToSLabelInteraction}
              onKeyDown={handleToSLabelInteraction}
              role="checkbox"
              aria-checked={acceptedToS}
              tabIndex={0}
              id="acceptance-label"
              data-cy="tos-checkbox-label"
              className={existingServiceApp ? 'md-font-color--disabled' : ''}
            >
              {!existingServiceApp
                ? t('serviceApp.acceptStatement')
                : t('serviceApp.acceptedStatement')}
              <a
                href="/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('serviceApp.termsOfService')}
              </a>
              {t('serviceApp.andForCheckbox')}
              <Link
                to={
                  'https://www.cisco.com/c/en/us/about/legal/privacy-full.html'
                }
                className="link"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('serviceApp.privacyPolicy')}
              </Link>
              .
            </span>
          </Checkbox>
          {!existingServiceApp ? (
            <EditButtons
              show={true}
              handleCancel={handleCreateCancel}
              handleSave={handleFormSubmit}
              isLoading={isSubmitting}
              disableSave={disableFormSubmit}
              appBtnText={t('serviceApp.addServiceApp')}
            />
          ) : null}
        </div>
        <div className="columns medium-2" />
      </div>

      <ErrorAlert showAlert={hasError} handleClick={setHasError} />
      {renderDialog()}
    </div>
  )
}

export default ServiceApp
