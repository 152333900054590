import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Select, SelectOption, InputSearch } from '@momentum-ui/react'
import ActionInput from '../ActionInput'
import httpService from '../../../services/httpService'
import { getBffBaseUrl } from '../../../services/configService'
import { IServiceApp } from '../../../interfaces/myAppInterfaces'
import CloudFailureIcon from '../../../assets/svgs/cloud-failure-icon.svg'
import PlugServiceDown from '../../../assets/svgs/plug-service-down-icon.svg'
import { logError } from '../../../services/loggerService'

interface IOrgAuthorizationsProps {
  canShow: boolean
  serviceApp?: IServiceApp
  token: string
  idBrokerUrl: string
}

export interface IAuthorizations {
  authorizerId: string
  orgId: string
  authorizationDate: string
  orgName: string
}

/**
 * @name OrgAuthorizations
 * @param { boolean } canShow whether or not to show the component
 * @param { IServiceApp } serviceApp existing service application
 * @param { string } token authorization bearer token
 * @param { string } idBrokerUrl idBrokerHost for http request parameter
 * @description
 * Shows a dropdown and search input of authorized organizations provided in 'list'.
 * If an organization is selected, a table containing the name and id will show as well as an input field
 * for a client secret that can be used to generate refresh and access tokens.
 */
const OrgAuthorizations: React.FC<IOrgAuthorizationsProps> = ({
  canShow,
  serviceApp,
  token,
  idBrokerUrl,
}) => {
  const { t } = useTranslation()
  const authorizationsList = serviceApp?.authorizationsList

  /**
   * activeOrganization can be in one of three states.
   * - undefined: default/initial state
   * - organization: org is found
   */
  const [activeOrganization, setActiveOrganization] = useState<
    IAuthorizations | undefined
  >(undefined)
  const [query, setQuery] = useState('')
  const [activeQuery, setActiveQuery] = useState(false)
  const [clientSecret, setClientSecret] = useState('')
  const [accessToken, setAccessToken] = useState('')
  const [refreshToken, setRefreshToken] = useState('')
  const [generateTokenFailure, setGenerateTokenFailure] = useState(false)

  const queryByOrgId = (submitEvent: React.FormEvent): void => {
    submitEvent.preventDefault()
    const foundOrganization = authorizationsList?.find(
      (org: IAuthorizations) => org.orgId === query
    )

    setActiveOrganization(foundOrganization)
    setActiveQuery(true)
  }

  const httpConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      idBrokerHost: idBrokerUrl,
    },
  }

  const generateTokens = async (): Promise<void> => {
    setAccessToken('')
    setRefreshToken('')
    const reqBody = {
      clientId: serviceApp?.clientId,
      clientSecret: clientSecret,
      targetOrgId: activeOrganization?.orgId,
    }

    try {
      const { data } = await httpService.post(
        `${getBffBaseUrl()}/v1/applications/${serviceApp?.id}/token`,
        reqBody,
        httpConfig
      )
      setAccessToken(data.access_token)
      setRefreshToken(data.refresh_token)
      setGenerateTokenFailure(false)
    } catch (err) {
      setGenerateTokenFailure(true)
      logError(`An error has occurred while generating tokens: ${err}`)
    }
  }

  const selectOrgName = (
    selected: Array<{ label: string; value: string }>
  ): void => {
    const selectedOrg = authorizationsList?.find((org: IAuthorizations) => {
      return org.orgId === selected[0].value
    })
    setActiveOrganization(selectedOrg)
    setGenerateTokenFailure(false)
  }

  return (
    <>
      {canShow && authorizationsList !== undefined && (
        <div className="form-row">
          <div className="columns medium-3">
            <label htmlFor="org-authorizations">
              <h3>{t('myApps.orgAuthorizations.label')}</h3>
            </label>
            <p>{t('myApps.orgAuthorizations.description')}</p>
          </div>
          <div className="columns medium-8 medium-offset-1 flex-col">
            <div className="flex-row">
              <Select
                key={
                  activeOrganization?.orgId
                    ? activeOrganization.orgId
                    : activeOrganization
                }
                defaultValue={
                  activeOrganization?.orgName
                    ? activeOrganization.orgName
                    : t('myApps.orgAuthorizations.dropdownPlaceholder')
                }
                className="org-select-dropdown medium-5"
                data-testid="org-dropdown"
                onSelect={selectOrgName}
              >
                {authorizationsList.map((org: IAuthorizations) => (
                  <SelectOption
                    data-testid="org-select-option"
                    key={org.orgId}
                    value={org.orgId}
                    label={org.orgName}
                  />
                ))}
              </Select>
              <InputSearch
                className="org-search-input medium-5"
                data-testid="org-search-input"
                placeholder={t('myApps.orgAuthorizations.searchPlaceholder')}
                onChange={(ev: any): void => setQuery(ev.target.value)}
                value={query}
              />
              {query ? (
                <Button
                  id="org-id-search"
                  className="input-action-btn medium-2"
                  type="submit"
                  ariaLabel={t('serviceApp.orgAuthorizations.search')}
                  data-testid="org-search-button"
                  onClick={queryByOrgId}
                >
                  {t('serviceApp.orgAuthorizations.search')}
                </Button>
              ) : (
                <div className="medium-2" />
              )}
            </div>
            <div className="flex-row">
              <div>
                {activeQuery && !activeOrganization ? (
                  <div className="auth-org-error">
                    <div className="icon-wrapper">
                      <CloudFailureIcon className="error-svg" />
                    </div>
                    <h3>{t('myApps.orgAuthorizations.noResults')}</h3>
                    <p>{t('myApps.orgAuthorizations.noResultsDescription')}</p>
                  </div>
                ) : null}
                {activeOrganization ? (
                  <>
                    <div id="authorized-org-display" className="medium-10">
                      <table>
                        <tbody>
                          <tr>
                            <th>{t('serviceApp.orgAuthorizations.orgName')}</th>
                            <th>{t('serviceApp.orgAuthorizations.orgId')}</th>
                          </tr>
                          <tr>
                            <td>{activeOrganization?.orgName}</td>
                            <td>{activeOrganization?.orgId}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="flex-row">
                      <div className="medium-8">
                        <ActionInput
                          className="org-client-secret"
                          name="client-secret"
                          htmlId="client-secret"
                          value={clientSecret}
                          onChange={(e: any): void => {
                            setClientSecret(e.target.value)
                            if (generateTokenFailure) {
                              setGenerateTokenFailure(false)
                            }
                          }}
                          placeholder={t(
                            'serviceApp.orgAuthorizations.clientSecretPlaceholder'
                          )}
                        />
                      </div>
                      <div className="medium-4">
                        {activeOrganization ? (
                          <Button
                            id="generate-tokens"
                            className="input-action-btn"
                            type="submit"
                            ariaLabel={t(
                              'serviceApp.orgAuthorizations.generateTokens'
                            )}
                            data-testid="generate-tokens-button"
                            onClick={generateTokens}
                            disabled={clientSecret.length < 1}
                          >
                            {t('serviceApp.orgAuthorizations.generateTokens')}
                          </Button>
                        ) : null}
                      </div>
                    </div>
                    {accessToken && refreshToken ? (
                      <>
                        <label htmlFor="refresh-token">
                          <span>
                            {t('serviceApp.orgAuthorizations.refreshToken')}
                          </span>
                        </label>
                        <ActionInput
                          name="refreshToken"
                          htmlId="refresh-token"
                          className="medium-10"
                          value={refreshToken}
                          readOnly
                          copyable
                          data-cy="refresh-token"
                        />
                        <label htmlFor="access-token">
                          <span>
                            {t('serviceApp.orgAuthorizations.accessToken')}
                          </span>
                        </label>
                        <ActionInput
                          name="accessToken"
                          htmlId="access-token"
                          className="medium-10"
                          value={accessToken}
                          readOnly
                          copyable
                          data-cy="access-token"
                        />
                      </>
                    ) : null}
                    {generateTokenFailure ? (
                      <div className="auth-org-error medium-10">
                        <div className="icon-wrapper">
                          <PlugServiceDown className="error-svg" />
                        </div>
                        <h3>{t('myApps.serviceDown')}</h3>
                        <p>{t('myApps.serviceDownDescription')}</p>
                      </div>
                    ) : null}
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default OrgAuthorizations
